import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-dashboard-list-skeleton',
  standalone: true,
  imports: [NgxSkeletonLoaderModule, CommonModule],
  template: `
    <div class="container-fluid skeleton-container">
      <!-- Row 1:  -->
      <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" class="mb-20">
        <div class="row align-items-center ">
          <div class="col-2">
            <ngx-skeleton-loader count="1" [theme]="squareTheme">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader
              count="1"
              [theme]="smallLineTheme"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-2">
            <ngx-skeleton-loader
              count="1"
              [theme]="mediumLineTheme"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-2 row">
            <div class="col-2">
              <ngx-skeleton-loader
                count="1"
                [theme]="circleTheme"
              ></ngx-skeleton-loader>
            </div>
            <div class="col-10">
              <ngx-skeleton-loader
                count="1"
                [theme]="mediumLineTheme"
              ></ngx-skeleton-loader>
            </div>
          </div>
          <div class="col-2">
            <ngx-skeleton-loader
              count="3"
              [theme]="circleTheme"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-1">
            <ngx-skeleton-loader
              count="1"
              [theme]="smallLineTheme"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-2">
            <ngx-skeleton-loader
              count="1"
              [theme]="mediumLineTheme"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-1">
            <ngx-skeleton-loader
              count="1"
              [theme]="smallCircleTheme"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .skeleton-container {
        padding: 20px;
      }
      .mb-20 {
        margin-bottom: 20px;
      }
    `,
  ],
})
export class DashboardListSkeletonComponent {
  circleTheme = {
    width: '25px',
    height: '25px',
    'background-color': '#D9D9D9',
    'border-radius': '12px',
    'margin-right': '14px',
  };

  squareTheme = {
    width: '25px',
    height: '25px',
    'border-radius': '4px',
    'background-color': '#D9D9D9',
    'margin-right': '16px',
  };
  smallCircleTheme = {
    width: '18px',
    height: '18px',
    'background-color': '#D9D9D9',
    'border-radius': '12px',
    'margin-right': '14px',
  };

  // Small Lines (Row 2 Right & Row 3)
  smallLineTheme = {
    height: '12px',
    width: '33%',
    'background-color': '#D9D9D9',
    'border-radius': '4px',
    'margin-bottom': '16px',
  };

  // Medium Line (Row 4)
  mediumLineTheme = {
    height: '12px',
    width: '60%',
    'background-color': '#D9D9D9',
    'border-radius': '4px',
    margin: '0',
  };
}
